import { useState } from "react";
import emailjs from "emailjs-com";
import { isEmpty } from "@utils/functions/isEmpty";

type EmailForm = {
  company_name: string;
  manager_name: string;
  email: string;
  phone_number: string;
  expect_member_count: string;
  description: string;
};

export const useContactEmailController = () => {
  const default_form: EmailForm = {
    company_name: ``,
    manager_name: ``,
    email: ``,
    phone_number: ``,
    expect_member_count: ``,
    description: ``,
  };

  const [emailForm, setEmailForm] = useState<EmailForm>(default_form);

  const updateEmailForm = (value: Partial<EmailForm>) => {
    setEmailForm({ ...emailForm, ...value });
  };

  const submitEmail = async () => {
    if (
      isEmpty(emailForm.company_name) ||
      isEmpty(emailForm.manager_name) ||
      isEmpty(emailForm.email) ||
      isEmpty(emailForm.phone_number) ||
      isEmpty(emailForm.expect_member_count)
    ) {
      alert(`필수 정보를 기입해주세요.`);
    } else {
      await emailjs.send(
        "redwit_goono_service",
        "goono_on_premise_contact",
        emailForm,
        "user_ohmy54jtemdYQE4VGty9J"
      );

      setEmailForm(default_form);
      alert(`상담 요청이 접수되었습니다.`);
    }
  };

  return {
    emailForm,
    updateEmailForm,
    submitEmail,
  };
};
