import { alpha, makeStyles } from "@material-ui/core";
import { theme } from "@theme";
import MainVisualBg from "@assets/images/content/main-section-bg.png";
import ProcessBg from "@assets/images/content/process-bg.png";

const HeaderHight = 107;
const Colors = {
	skyblue: "#DEE7FF",
	primaryBlue: "#16339a",
	titleColor: "#2c2c2c",
	subtitleColor: "#777",
	descriptionColor: "#555",
	grayBackground: "#E2E2E2",
	footerImgColor: "#CBCBCB",
	secondaryBlue: "#2475f8",
	blackColor: "#111",
};

export const useHomeScreenStyles = makeStyles(() => ({
	wrap: {
		width: "100%",
		height: "100%",
		display: "flex",
		flexDirection: "column",
		background: theme.white,
		minWidth: 1280,
		fontFamily: "Pretendard",
		"& *": {
			fontFamily: "inherit !important",
		},
	},
	container: {
		maxWidth: 1282 + 40,
		padding: "0 20px",
	},
	header_wrap: {
		position: "fixed",
		width: "100%",
		top: 0,
		left: 0,
		transition: "all .25s",

		"&.fix-header": {
			background: alpha(theme.white, 0.8),
			zIndex: 99,
			...theme.shadow_2,
			backdropFilter: "blur(10px)",
			"& .header-content": {
				height: 80,
			},
		},
		"& .header-content": { height: HeaderHight },
	},

	main_visual_section: {
		paddingTop: HeaderHight,
		height: 789,
		background: `linear-gradient(273deg, ${Colors.skyblue} 1.43%, rgba(229, 235, 250, 0.00) 98%),url(${MainVisualBg}) no-repeat center right`,
		...theme.flex_center,

		"& .left-box": {
			flex: "1 1 746px",
		},
		"& .title": { fontSize: 65, lineHeight: 1.3, letterSpacing: "-1.95px" },
		"& .title.title-light": {
			marginTop: 10,
			fontWeight: 300,
			color: theme.gray_9_title,
		},
		"& .title.title-bold": {
			fontWeight: 700,
			color: Colors.primaryBlue,
		},
		"& .desc": {
			marginTop: 4,
			fontSize: 20,
			fontWeight: 400,
			color: theme.gray_7_text,
			whiteSpace: "pre-line",
		},
		"& .download-btn": {
			marginTop: 82,
			width: 270,
		},
		"& .right-box": {
			flex: "1 1 766px",
		},
	},

	"@keyframes bounce-in-fwd": {
		"0%": {
			"-webkit-transform": "scale(0)",
			transform: "scale(0)",
			"-webkit-animation-timing-function": "ease-in",
			"animation-timing-function": "ease-in",
			opacity: "0",
		},
		"38%": {
			"-webkit-transform": "scale(1)",
			transform: "scale(1)",
			"-webkit-animation-timing-function": "ease-out",
			"animation-timing-function": "ease-out",
			opacity: "1",
		},
		"55%": {
			"-webkit-transform": "scale(0.7)",
			transform: "scale(0.7)",
			"-webkit-animation-timing-function": "ease-in",
			"animation-timing-function": "ease-in",
		},
		"72%": {
			"-webkit-transform": "scale(1)",
			transform: "scale(1)",
			"-webkit-animation-timing-function": "ease-out",
			"animation-timing-function": "ease-out",
		},
		"81%": {
			"-webkit-transform": "scale(0.84)",
			transform: "scale(0.84)",
			"-webkit-animation-timing-function": "ease-in",
			"animation-timing-function": "ease-in",
		},
		"89%": {
			"-webkit-transform": "scale(1)",
			transform: "scale(1)",
			"-webkit-animation-timing-function": "ease-out",
			"animation-timing-function": "ease-out",
		},
		"95%": {
			"-webkit-transform": "scale(0.95)",
			transform: "scale(0.95)",
			"-webkit-animation-timing-function": "ease-in",
			"animation-timing-function": "ease-in",
		},
		"100%": {
			"-webkit-transform": "scale(1)",
			transform: "scale(1)",
			"-webkit-animation-timing-function": "ease-out",
			"animation-timing-function": "ease-out",
		},
	},

	primary_button: {
		"&&": {
			height: 60,
			padding: "0 30px",
			borderRadius: 80,
			justifyContent: "space-between",
			color: theme.white,
			background: "linear-gradient(244deg, #7320DC -32.19%, #2D83D2 105.65%)",
			fontSize: 18,
			fontWeight: 500,
			transition: "all .25s",
		},
		"&&:hover .MuiSvgIcon-root": { animation: "$bounce-in-fwd 0.8s both" },
	},

	carousel_section: {
		padding: "110px 0",
		// overflow: "hidden",

		"& .left-box": {
			width: 249,
			marginRight: 94,
			flexShrink: 0,
		},
		"& .right-box": {
			maxWidth: "100%",
			overflowX: "hidden",
			position: "relative",
		},

		"& .title": {
			fontSize: 55,
			letterSpacing: "-1.65px",
			lineHeight: 1.2,

			"&.title-noraml": {
				color: Colors.titleColor,
				fontWeight: 400,
			},
			"&.title-bold": {
				color: Colors.primaryBlue,
				fontWeight: 700,
			},
		},
		"& .desc": {
			marginTop: 20,
			fontSize: 18,
			color: Colors.descriptionColor,
			fontWeight: 400,
			whiteSpace: "pre-line",
		},
		"& .btn-wrap": {
			marginTop: 50,
			gap: 12,

			"& .navi-btn": {
				width: 60,
				height: 60,
				borderRadius: "50%",
				border: `1px solid ${theme.gray_5_body}`,
				color: theme.gray_6,

				"&.Mui-disabled": {
					color: theme.gray_3,
					borderColor: theme.gray_3,
				},
			},
		},
		"& .swiper-pagination-progressbar.swiper-pagination-horizontal": {
			position: "relative",
			marginTop: 59,
			height: 3,
			background: Colors.grayBackground,
			borderRadius: 60,

			"& .swiper-pagination-progressbar-fill": {
				background: Colors.primaryBlue,
				borderRadius: 60,
			},
		},
	},
	carousel_item: {
		padding: "47px 32px 25px 32px",
		height: 320,
		borderRadius: 20,
		background: "#f3f5f8",
		position: "relative",

		"& .title": {
			fontSize: 24,
			fontWeight: 700,
			color: "#333",
		},
		"& .sub-title": {
			marginTop: 16,
			fontSize: 17,
			fontWeight: 400,
			color: Colors.subtitleColor,
			whiteSpace: "pre-line",
			lineHeight: 1.7,
		},
		"& .img": {
			position: "absolute",
			right: 32,
			bottom: 25,
		},
	},
	form_section: {
		padding: "100px 0",
		background: "#f8f8f8",

		"& .left-box": {
			marginTop: 28,
		},
		"& .right-box": {
			width: "100%",
			maxWidth: 800,
		},
		"& .title": {
			fontSize: 42,
			lineHeight: 1.4,
			letterSpacing: "-1.68px",

			"&.title-bold": {
				color: Colors.primaryBlue,
				fontWeight: 700,
			},
			"&.title-normal": {
				color: Colors.titleColor,
				fontWeight: 500,
			},
		},
		"& .desc": {
			marginTop: 20,
			color: Colors.descriptionColor,
			fontSize: 18,
			fontWeight: 400,
			letterSpacing: "-0.18px",
			whiteSpace: "pre-line",
		},
		"& .contact-btn": {
			marginTop: 112,
			width: 225,
		},
		"& .form-group": {},
		"& .form-base ": {
			width: "100%",
			marginBottom: 20,
		},
		"& .form-base .MuiInputBase-formControl": {
			width: "100%",
			minHeight: 80,
			borderRadius: 10,
			padding: "0 30px",
			background: theme.white,
			justifyContent: "center",
			fontSize: 18,
		},
		"& .text-area.form-base .MuiInputBase-formControl": {
			padding: "26px 30px",
			"&  textarea": {
				minHeight: 166 - 52,
			},
		},
		"& .form-base .adornment": {
			color: theme.gray_8,
			fontSize: 18,
			fontWeight: 700,
			"&::after": {
				content: '" "',
				marginLeft: 4,
				marginBottom: 4,
				width: 6,
				height: 6,
				borderRadius: "50%",
				background: theme.primary,
				alignSelf: "flex-end",
			},
		},
	},
	customer_section: {
		paddingTop: 100,
		paddingBottom: 90,

		"& .title-box": { marginBottom: 60 },
		"& .title": {
			fontSize: 36,
			fontWeight: 400,
			lineHeight: 1.5,
			letterSpacing: -1.44,
			color: Colors.titleColor,
			textAlign: "center",
		},
		"& .title.bold": {
			fontWeight: 700,
			"& span": { color: Colors.secondaryBlue },
		},
	},

	process_section: {
		paddingTop: 110,
		paddingBottom: 130,
		background: `#070E53 url(${ProcessBg}) no-repeat center center / cover `,
		color: theme.white,

		"& .title": {
			fontSize: 48,
			fontWeight: 700,
			lineHeight: 1.3,
			letterSpacing: -0.48,
			whiteSpace: "pre-line",

			"& .dot": {
				display: "inline-block",
				marginLeft: 8,
				width: 8,
				height: 8,
				borderRadius: "50%",
				background: Colors.secondaryBlue,
			},
		},
		"& .desc": {
			marginTop: 20,
			fontSize: 18,
			opacity: 0.6,
		},
		"& .process-box": {
			marginTop: 80,
		},
		"& .process-item": {
			marginRight: 80,
			width: 264,

			"& .item-title": {
				fontSize: 20,
				fontWeight: 700,
				letterSpacing: -0.66,
				marginTop: 32,
			},
			"& .item-text": {
				marginTop: 20,
				whiteSpace: "pre-line",
				fontSize: 18,
				lineHeight: "30px",
				opacity: 0.8,
			},
		},
	},
	footer: {
		paddingTop: 36,
		paddingBottom: 56,
		background: Colors.blackColor,
		color: theme.white,

		"& .footer-top": {
			paddingBottom: 18,
			alignItems: "center",
			borderBottom: `1px solid ${alpha(theme.white, 0.2)}`,

			"& .divider": {
				width: 1,
				height: 7,
				background: theme.white,
				opacity: 0.3,
				margin: "0 16px",
			},
		},
		"& .top-link": {
			fontSize: 14,
			fontWeight: 500,
			opacity: 0.6,
		},

		"& .footer-bottom": {
			marginTop: 20,
		},
		"& .footer-logo, & .footer-logo path": {
			color: Colors.footerImgColor,
			fill: Colors.footerImgColor,
		},
		"& .sns-logo": {
			width: 40,
			textAlign: "center",
			opacity: 0.5,
		},
		"& .info-row": {
			marginTop: 16,
			gap: 12,

			"& .MuiTypography-body1": {
				fontSize: 14,
				fontWeight: 400,
				color: theme.gray_1,
			},
		},
		"& .copyright": {
			marginTop: 8,
			color: theme.gray_3,
			fontSize: 14,
			fontWeight: 300,
			opacity: 0.5,
		},
	},
}));
