import React from "react";
import { Container, Box, Typography } from "@material-ui/core";

import { useHomeScreenStyles } from "./styles";

import clsx from "clsx";
import PartnerRolling from "./PartnerRolling";

export default function PartnerBannerSection() {
	const classes = useHomeScreenStyles();

	return (
		<Box className={classes.customer_section}>
			<Container className={classes.container}>
				<Box className="title-box">
					<Typography className={clsx("title", "bold")}>
						구노는
						<span> 2,000</span>
						여회 이상의 판매 실적을 보유하고 있습니다.
					</Typography>
					<Typography className={clsx("title")}>
						연구원과 기관에 최적의 전자연구노트 서비스를 제공해드리겠습니다
					</Typography>
				</Box>
			</Container>
			<Box>
				<PartnerRolling />
			</Box>
		</Box>
	);
}
