import React from "react";
import { Container, Box } from "@material-ui/core";
import Flex from "@ui/goono/components/layouts/Flex";

import { useHomeScreenStyles } from "./styles";
import { ReactComponent as GoonoLogo } from "@assets/images/logo/header-logo.svg";
import clsx from "clsx";

export default function Header() {
	const classes = useHomeScreenStyles();
	const [headerFix, setHeaderFix] = React.useState<boolean>(false);

	const onScroll = () => {
		const scrollTop = window.scrollY;
		setHeaderFix(scrollTop >= 150);
	};

	React.useEffect(() => {
		window.addEventListener("scroll", onScroll);
		return () => window.removeEventListener("scroll", onScroll);
	}, []);

	return (
		<Box className={clsx(classes.header_wrap, headerFix && "fix-header")}>
			<Container className={classes.container}>
				<Flex container alignItems="center" className="header-content">
					<h1>
						<a href="#">
							<GoonoLogo />
						</a>
					</h1>
				</Flex>
			</Container>
		</Box>
	);
}
