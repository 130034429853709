import React from "react";
import { Container, Box, Typography, ButtonBase } from "@material-ui/core";
import Flex from "@ui/goono/components/layouts/Flex";

import { useHomeScreenStyles } from "./styles";
import SlideImg01 from "@assets/images/content/slide-img-01.png";
import SlideImg02 from "@assets/images/content/slide-img-02.png";
import SlideImg03 from "@assets/images/content/slide-img-03.png";
import SlideImg04 from "@assets/images/content/slide-img-04.png";
import SlideImg05 from "@assets/images/content/slide-img-05.png";
import SlideImg06 from "@assets/images/content/slide-img-06.png";

import { ReactComponent as ArrowLeft } from "@assets/images/icon/icon-left-arrow.svg";
import { ReactComponent as ArrowRight } from "@assets/images/icon/icon-right-arrow.svg";

// Import Swiper React components
import { Swiper, SwiperSlide, SwiperClass } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import clsx from "clsx";

export default function CarouselSection() {
	const classes = useHomeScreenStyles();
	const [swiper, setSwiper] = React.useState<SwiperClass>();
	const [siwperIndex, setSwiperIndex] = React.useState<number | undefined>(0);

	const slides = [
		{
			title: "관내 데이터 저장",
			subTitle: "기업 및 내부 저장공간에 \n 연구노트 데이터 저장",
			img: SlideImg01,
		},
		{
			title: "에디터 및 파일 업로드 지원",
			subTitle:
				"에디터로 연구노트를 생성하고 \n JPG, PNG, PDF, DOCS 등\n 다양한 파일 업로드로 연구노트 생성",
			img: SlideImg02,
		},
		{
			title: "단계별 연구노트 관리",
			subTitle:
				"관리자, 책임자 기능으로 \n 작성 중·승인·보류 각 단계에 따라 \n 한 눈에 관리 가능",
			img: SlideImg03,
		},
		{
			title: "쉽고 빠른 검색",
			subTitle: "연구원·소유자별 연구 데이터를 \n 빠르고 정확하게 검색",
			img: SlideImg04,
		},
		{
			title: "편리한 UI/UX 제공 ",
			subTitle:
				"기존 구노 서비스와 유사한 \n 최신 UI/UX 제공으로 \n 작성 효율 증대",
			img: SlideImg05,
		},
		{
			title: "저렴한 비용",
			subTitle:
				"기존 구축형 서비스에 비해 \n 저렴한 가격으로 \n 전자연구노트 구축 가능",
			img: SlideImg06,
		},
	];

	const renderSlides = () => {
		return slides.map((slide, index) => (
			<SwiperSlide key={index}>
				<Box className={classes.carousel_item}>
					<Typography className={"title"} children={slide.title} />
					<Typography className={"sub-title"} children={slide.subTitle} />
					<Box className="img">
						<img src={slide.img} alt="" />
					</Box>
				</Box>
			</SwiperSlide>
		));
	};

	const renderSwiper = () => {
		return (
			<Swiper
				spaceBetween={15}
				slidesPerView={3}
				pagination={{
					el: ".custom-pagination",
					type: "progressbar",
					renderProgressbar: function (progressbarFillClass: any) {
						return (
							'<span class="' +
							progressbarFillClass +
							'" style="width: 100%;"></span>'
						);
					},
				}}
				modules={[Pagination, Navigation]}
				watchSlidesProgress={true}
				autoplay
				onSlideChange={() => {
					console.log(swiper?.realIndex);
					setSwiperIndex(swiper?.realIndex);
				}}
				onSwiper={(e: SwiperClass) => {
					setSwiper(e);
				}}
			>
				{renderSlides()}
			</Swiper>
		);
	};

	const renderSwiperNavi = () => {
		return (
			<Flex container className="btn-wrap">
				<ButtonBase
					className="navi-btn"
					disabled={siwperIndex === 0}
					onClick={() => swiper?.slidePrev()}
				>
					<ArrowLeft />
				</ButtonBase>
				<ButtonBase
					className="navi-btn"
					disabled={siwperIndex === 3}
					onClick={() => swiper?.slideNext()}
				>
					<ArrowRight />
				</ButtonBase>
			</Flex>
		);
	};

	const renderLeftBox = () => {
		return (
			<Box className="left-box">
				<Typography className={clsx("title", "title-normal")} children="WHY" />
				<Typography className={clsx("title", "title-bold")} children="GOONO?" />
				<Typography
					className={"desc"}
					children={`꼭 필요한 기능들만 모은 서비스로 \n 에디터로 연구노트를 쉽게 작성하고 \n 관리자, 책임자 기능으로 \n 효율적으로 관리할 수 있어요.`}
				/>
				{renderSwiperNavi()}
			</Box>
		);
	};

	const renderRightBox = () => {
		return (
			<Box className="right-box">
				{renderSwiper()}
				<Box className="custom-pagination" />
			</Box>
		);
	};
	return (
		<Box className={classes.carousel_section}>
			<Container className={classes.container}>
				<Flex container style={{ width: "100%" }}>
					{renderLeftBox()}
					{renderRightBox()}
				</Flex>
			</Container>
		</Box>
	);
}
