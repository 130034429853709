import React from "react";
import { Grid, makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";

import TeleIcon from "@assets/images/content/partners/top/logo1-1.png";
import SunamIcon from "@assets/images/content/partners/top/logo1-2.png";
import LakeIcon from "@assets/images/content/partners/top/logo1-3.png";
import RfhicIcon from "@assets/images/content/partners/top/logo1-4.png";
import MeereIcon from "@assets/images/content/partners/top/logo1-5.png";
import CancerIcon from "@assets/images/content/partners/top/logo1-6.png";
import KhnmcIcon from "@assets/images/content/partners/top/logo1-7.png";
import KnuhIcon from "@assets/images/content/partners/top/logo1-8.png";
import KbrlIcon from "@assets/images/content/partners/top/logo1-9.png";
import BusinessIcon from "@assets/images/content/partners/top/logo1-10.png";
import SnuhIcon from "@assets/images/content/partners/top/logo1-11.png";
import ShbIcon from "@assets/images/content/partners/top/logo1-12.png";
import WeatherIcon from "@assets/images/content/partners/top/logo1-13.png";
import IntekIcon from "@assets/images/content/partners/top/logo1-14.png";
import PnuhIcon from "@assets/images/content/partners/top/logo1-15.png";
import PaikIcon from "@assets/images/content/partners/top/logo1-16.png";
import CnuhIcon from "@assets/images/content/partners/top/logo1-17.png";
import CmcdjIcon from "@assets/images/content/partners/top/logo1-18.png";
import HyundaiIcon from "@assets/images/content/partners/top/logo1-19.png";
import CbnuhIcon from "@assets/images/content/partners/top/logo1-20.png";

import GistIcon from "@assets/images/content/partners/bottom/logo2-1.png";
import IaeIcon from "@assets/images/content/partners/bottom/logo2-2.png";
import KrictIcon from "@assets/images/content/partners/bottom/logo2-3.png";
import mabikIcon from "@assets/images/content/partners/bottom/logo2-4.png";
import SchIcon from "@assets/images/content/partners/bottom/logo2-5.png";
import JeisysIcon from "@assets/images/content/partners/bottom/logo2-6.png";
import CnuIcon from "@assets/images/content/partners/bottom/logo2-7.png";
import KaistIcon from "@assets/images/content/partners/bottom/logo2-8.png";
import AmcSeoulIcon from "@assets/images/content/partners/bottom/logo2-9.png";
import EuclidIcon from "@assets/images/content/partners/bottom/logo2-10.png";
import KatechIcon from "@assets/images/content/partners/bottom/logo2-11.png";
import EotechIcon from "@assets/images/content/partners/bottom/logo2-12.png";
import HumaxIcon from "@assets/images/content/partners/bottom/logo2-13.png";
import CauIcon from "@assets/images/content/partners/bottom/logo2-14.png";
import FilaIcon from "@assets/images/content/partners/bottom/logo2-15.png";
import KtlIcon from "@assets/images/content/partners/bottom/logo2-16.png";
import PusanAcIcon from "@assets/images/content/partners/bottom/logo2-17.png";
import WrtnIcon from "@assets/images/content/partners/bottom/logo2-18.png";
import KmaIcon from "@assets/images/content/partners/bottom/logo2-19.png";
import UosAcIcon from "@assets/images/content/partners/bottom/logo2-20.png";

const partners = [
	TeleIcon,
	SunamIcon,
	LakeIcon,
	RfhicIcon,
	MeereIcon,
	CancerIcon,
	KhnmcIcon,
	KnuhIcon,
	KbrlIcon,
	BusinessIcon,
	SnuhIcon,
	ShbIcon,
	WeatherIcon,
	IntekIcon,
	PnuhIcon,
	PaikIcon,
	CnuhIcon,
	CmcdjIcon,
	HyundaiIcon,
	CbnuhIcon,
];

const partners2 = [
	GistIcon,
	IaeIcon,
	KrictIcon,
	mabikIcon,
	SchIcon,
	JeisysIcon,
	CnuIcon,
	KaistIcon,
	AmcSeoulIcon,
	EuclidIcon,
	KatechIcon,
	EotechIcon,
	HumaxIcon,
	CauIcon,
	FilaIcon,
	KtlIcon,
	PusanAcIcon,
	WrtnIcon,
	KmaIcon,
	UosAcIcon,
];

const isPartnerHaveIcon = (p: string) => p.length > 2;
const PARTNERS_FILTER_LENGTH = partners.filter(isPartnerHaveIcon).length;

const useStyles = makeStyles((MuiTheme: Theme) => ({
	root: {
		width: "100%",
		overflowX: "hidden",
	},
	slider_container: {
		width: "100%",
		height: 90,
		margin: "0 auto",
		position: "relative",
		transform: "translate3d(0, 0, 1px)",
		overflow: "hidden",
		willChange: "auto",
	},
	slider_body: {
		height: "100%",
		display: "flex",
		position: "absolute",
		top: 0,
		left: 0,
		transform: "translate3d(0, 0, 0)",
		animation: `$moveSlideshow ${PARTNERS_FILTER_LENGTH * 2}s linear infinite`,
	},
	slider_content: {
		width: 200,
		height: "100%",
		marginLeft: 20,
		backgroundColor: "white",
	},
	slider_content_image: {
		alignSelf: "center",
		width: "100%",
		objectFit: "contain",
	},

	bottom_slider_container: {
		marginTop: "40px",
		"& $slider_body": { paddingLeft: 100 },
	},
	[MuiTheme.breakpoints.down(731)]: {
		bottom_slider_container: { marginTop: 10 },
	},
	[MuiTheme.breakpoints.down(441)]: {
		slider_container: { height: 60 },
		slider_content: {
			width: 134,
			marginLeft: 10,
		},
		bottom_slider_container: {
			"& $slider_body": { paddingLeft: 134 / 2 },
		},
		"@keyframes moveSlideshow": {
			"100%": {
				transform: `translateX(-${PARTNERS_FILTER_LENGTH * (134 + 10)}px)`,
			},
		},
	},
	"@keyframes moveSlideshow": {
		"100%": {
			transform: `translateX(-${PARTNERS_FILTER_LENGTH * (200 + 20)}px)`,
		},
	},
}));

export default function PartnerRolling() {
	const classes = useStyles();

	const render_slider_body = (partner_arr: string[]) => {
		return partner_arr.concat(partner_arr).map((p, idx) => (
			<React.Fragment key={`${p}::${idx}`}>
				{isPartnerHaveIcon(p) && (
					<Grid
						item
						container
						direction="column"
						alignItems="center"
						justifyContent="center"
						className={classes.slider_content}
					>
						<img
							alt="Goono partner"
							className={classes.slider_content_image}
							src={p}
						/>
					</Grid>
				)}
			</React.Fragment>
		));
	};

	const render_slider = (props: { partners: string[]; className?: string }) => {
		return (
			<div className={clsx(classes.slider_container, props.className)}>
				<div className={classes.slider_body}>
					{render_slider_body(props.partners)}
				</div>
			</div>
		);
	};

	return (
		<div className={classes.root}>
			{render_slider({
				partners: partners,
			})}
			{render_slider({
				partners: partners2,
				className: classes.bottom_slider_container,
			})}
		</div>
	);
}
