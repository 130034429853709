import React from "react";
import { Box } from "@material-ui/core";

import { useHomeScreenStyles } from "./styles";
import Header from "./Header";
import MainVisualSection from "./MainVisualSection";
import CarouselSection from "./CarouselSection";
import FormSection from "./FormSection";
import CustomerBannerSection from "./PartnerBannerSection";
import ProcessSection from "./ProcessSection";
import Footer from "./Footer";

export default function HomeScreen() {
	const classes = useHomeScreenStyles();

	return (
		<Box className={classes.wrap}>
			<Header />
			<MainVisualSection />
			<CarouselSection />
			<FormSection />
			<CustomerBannerSection />
			<ProcessSection />
			<Footer />
		</Box>
	);
}
