import React from "react";
import { Container, Box, Typography, ButtonBase } from "@material-ui/core";
import Flex from "@ui/goono/components/layouts/Flex";

import { useHomeScreenStyles } from "./styles";

import { ReactComponent as GoonoLogo } from "@assets/images/logo/header-logo.svg";
import InstaLogo from "@assets/images/logo/logo-insta.png";
import TalkLogo from "@assets/images/logo/logo-talk.png";
import YoutubeLogo from "@assets/images/logo/logo-youtube.png";
import BlogLogo from "@assets/images/logo/logo-blog.png";

export default function Footer() {
	const classes = useHomeScreenStyles();

	const snsLogos = [
		{ src: InstaLogo, url: "https://www.instagram.com/goono_official/" },
		{ src: TalkLogo, url: "https://talk.naver.com/W4ZJC7" },
		{ src: YoutubeLogo, url: "https://www.youtube.com/@goono5350" },
		{ src: BlogLogo, url: "https://blog.naver.com/redwit2019" },
	];

	const renderFooterTop = () => {
		return (
			<Flex container className="footer-top">
				<ButtonBase children={"개인정보처리방침"} className="top-link" />
				<div className="divider" />
				<ButtonBase children={"서비스 이용약관"} className="top-link" />
			</Flex>
		);
	};

	const renderSnsButtons = () => {
		return (
			<Flex container gap="12px">
				{snsLogos.map((logo, index) => (
					<ButtonBase
						className="sns-logo"
						key={index}
						onClick={() => window.open(logo.url, "_blank")}
					>
						<img src={logo.src} alt="" />
					</ButtonBase>
				))}
			</Flex>
		);
	};

	const renderFooterInfo = () => {
		return (
			<Flex container className="info-row">
				<Typography>
					대전광역시 유성구 어은로 44, 한빛빌딩 2층 (34139)
				</Typography>
				<Typography>대표번호 : 042-861-5001</Typography>
				<Typography>E-mail : contact@redwit.io</Typography>
				<Typography>Fax : 042-861-5002</Typography>
				<Typography>사업자 번호 : 144-87-01498</Typography>
			</Flex>
		);
	};

	const renderCopyright = () => {
		return (
			<Typography
				className="copyright"
				children="Copyright ⓒ Redwit All rights reserved."
			/>
		);
	};
	const renderFooterBottom = () => {
		return (
			<Box className="footer-bottom">
				<Flex container alignItems="center" justifyContent="space-between">
					<GoonoLogo className="footer-logo" />
					{renderSnsButtons()}
				</Flex>
				{renderFooterInfo()}
				{renderCopyright()}
			</Box>
		);
	};
	return (
		<Box className={classes.footer}>
			<Container className={classes.container}>
				{renderFooterTop()}
				{renderFooterBottom()}
			</Container>
		</Box>
	);
}
