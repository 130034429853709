import React from "react";
import { Container, Box, Typography, ButtonBase } from "@material-ui/core";
import Flex from "@ui/goono/components/layouts/Flex";
import GoonoTypography from "@ui/goono/components/typo/GoonoTypography";

import { useHomeScreenStyles } from "./styles";
import clsx from "clsx";
import MainVisualImage from "@assets/images/content/main-section-content.png";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";

export default function MainVisualSection() {
	const classes = useHomeScreenStyles();

	return (
		<Box className={classes.main_visual_section}>
			<Container className={classes.container}>
				<Flex container alignItems="center">
					<Box className="left-box">
						<GoonoTypography
							type="h2"
							textcolor="primary"
							children="구노 구축형 라이트"
						/>
						<Typography
							className={clsx("title", "title-light")}
							children="연구 기관 및 기업을 위한"
						/>
						<Typography
							className={clsx("title", "title-bold")}
							children="최적의 전자연구노트"
						/>
						<Typography
							className={"desc"}
							children={`복잡하고 어려웠던 전자연구노트의 작성부터 \n 승인, 관리까지 구노 구축형 라이트로 간편해집니다.`}
						/>
						<ButtonBase
							className={clsx(classes.primary_button, "download-btn")}
							onClick={() => {
								// TODO: 서비스 소개서 다운로드
							}}
						>
							서비스 소개서 다운로드
							<GetAppRoundedIcon />
						</ButtonBase>
					</Box>
					<Box className="right-box">
						<img src={MainVisualImage} alt="" style={{ maxWidth: "100%" }} />
					</Box>
				</Flex>
			</Container>
		</Box>
	);
}
