import React from "react";
import { Container, Box, Typography } from "@material-ui/core";
import Flex from "@ui/goono/components/layouts/Flex";

import { useHomeScreenStyles } from "./styles";

import ProcessImg01 from "@assets/images/content/process-img-01.png";
import ProcessImg02 from "@assets/images/content/process-img-02.png";
import ProcessImg03 from "@assets/images/content/process-img-03.png";
import ProcessImg04 from "@assets/images/content/process-img-04.png";
import { ReactComponent as ArrowIcon } from "@assets/images/content/process-arrow.svg";

export default function ProcessSection() {
	const classes = useHomeScreenStyles();

	return (
		<Box className={classes.process_section}>
			<Container className={classes.container}>
				<Box>
					<Typography className="title">
						{`구노 구축형 라이트 \n 사용 프로세스`}
						<span className="dot"></span>
					</Typography>
					<Typography
						className="desc"
						children={
							"복잡한 과정없이 연구노트를 완성하고 체계적으로 관리할 수 있습니다."
						}
					/>
				</Box>
				<Flex container className="process-box">
					{[
						{
							img: ProcessImg01,
							title: "프로젝트 및 연구노트 생성",
							text: "프로젝트 및 연구노트를 \n 과제별로 생성합니다.",
						},
						{
							img: ProcessImg02,
							title: "점검",
							text: "생성된 프로젝트 및 노트를 \n 연구 단계 별로 점검합니다.",
						},
						{
							img: ProcessImg03,
							title: "승인",
							text: "프로젝트는 관리자 및 \n 책임자의 승인 절차를 거칩니다.",
						},
						{
							img: ProcessImg04,
							title: "연구노트 완성",
							text: "완성된 연구노트를 PDF로 \n 다운받아 제출 가능합니다.",
							showArrow: false,
						},
					].map((item, index) => (
						<Box key={index} className="process-item">
							<Flex
								container
								alignItems="center"
								justifyContent="space-between"
								style={{ height: 80 }}
							>
								<img src={item.img} alt="" />
								{item.showArrow !== false && <ArrowIcon />}
							</Flex>
							<Typography className="item-title" children={item.title} />
							<Typography className="item-text" children={item.text} />
						</Box>
					))}
				</Flex>
			</Container>
		</Box>
	);
}
