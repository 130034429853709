import React from "react";
import {
  Container,
  Box,
  Typography,
  ButtonBase,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import Flex from "@ui/goono/components/layouts/Flex";
import { useHomeScreenStyles } from "./styles";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import clsx from "clsx";
import { useContactEmailController } from "./hooks/useContactEmailController";

export default function FormSection() {
  const classes = useHomeScreenStyles();
  const contactEmailController = useContactEmailController();

  const renderTextField = (
    id: string,
    label: string,
    value: string,
    setValue: (newValue: string) => void
  ) => (
    <TextField
      id={id}
      className="form-base"
      InputProps={{
        value: value,
        onChange: (evt) => {
          setValue(evt.target.value);
        },
        startAdornment: (
          <InputAdornment
            className="adornment"
            disableTypography
            position="start"
            children={label}
          />
        ),
        disableUnderline: true,
      }}
    />
  );

  const renderSectionTitleGroup = () => {
    return (
      <Box className="left-box">
        <Typography
          className={clsx("title", "title-bold")}
          children={"구노 구축형 라이트"}
        />
        <Typography
          className={clsx("title", "title-normal")}
          children={"상담을 희망하시나요?"}
        />
        <Typography
          className={"desc"}
          children={`문의하신 사항에 회신드리기 위한 \n 기본 정보를 작성해주세요. \n 담당자가 빠른 시일 내에 연락드리겠습니다.`}
        />
        <ButtonBase
          className={clsx(classes.primary_button, "contact-btn")}
          onClick={contactEmailController.submitEmail}
        >
          상담 요청 남기기
          <ChevronRightRoundedIcon />
        </ButtonBase>
      </Box>
    );
  };

  const renderFormGroup = () => {
    return (
      <Box className="right-box">
        <Box className="form-group">
          {renderTextField(
            "organization",
            "기업/기관명",
            contactEmailController.emailForm.company_name,
            (v) =>
              contactEmailController.updateEmailForm({
                company_name: v,
              })
          )}
          {renderTextField(
            "contactPerson",
            "담당자 성함",
            contactEmailController.emailForm.manager_name,
            (v) =>
              contactEmailController.updateEmailForm({
                manager_name: v,
              })
          )}
          {renderTextField(
            "emailAddress",
            "이메일 주소",
            contactEmailController.emailForm.email,
            (v) =>
              contactEmailController.updateEmailForm({
                email: v,
              })
          )}
          <Flex container gap="20px">
            {renderTextField(
              "phoneNumber",
              "전화번호",
              contactEmailController.emailForm.phone_number,
              (v) =>
                contactEmailController.updateEmailForm({
                  phone_number: v,
                })
            )}
            {renderTextField(
              "numberOfUsers",
              "사용 희망인원",
              contactEmailController.emailForm.expect_member_count,
              (v) =>
                contactEmailController.updateEmailForm({
                  expect_member_count: v,
                })
            )}
          </Flex>
          <TextField
            id="description"
            className={clsx("form-base", "text-area")}
            multiline
            placeholder={`기타 문의사항이 있으면 입력해주세요.\n(커스터마이징이 필요한 기능이 있으시면 상세 기재해 주세요.)`}
            InputProps={{
              disableUnderline: true,
              value: contactEmailController.emailForm.description,
              onChange: (evt) =>
                contactEmailController.updateEmailForm({
                  description: evt.target.value,
                }),
            }}
          />
        </Box>
      </Box>
    );
  };
  return (
    <Box className={classes.form_section}>
      <Container className={classes.container}>
        <Flex
          container
          justifyContent="space-between"
          style={{ width: "100%" }}
        >
          {renderSectionTitleGroup()}
          {renderFormGroup()}
        </Flex>
      </Container>
    </Box>
  );
}
