import React from "react";
import GoonoThemeProvider, {
	GoonoTheme,
} from "@ui/goono/styles/GoonoThemeProvider";
import { ThemeProvider as EmotionThemeProvider } from "@emotion/react";
import HomeScreen from "./screens/HomeScreen";

export default function App() {
	return (
		<EmotionThemeProvider theme={GoonoTheme}>
			<GoonoThemeProvider>
				<HomeScreen />
			</GoonoThemeProvider>
		</EmotionThemeProvider>
	);
}
